.tour-dates-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000000;
  }
  
  .tour-dates-modal-content {
    background-color: #c0c0c0;
    color: black;
    border-radius: 5px;
    width: 50vw;
    height: 60vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .modal-title-bar {
    display: flex;
    justify-content: space-between; /* Revert to space-between */
    align-items: center;
    background-color: #000080; /* Example background color */
    color: #fff; /* Text color */
    padding: 0px;
    width: 100%;
  }
  .modal-title {
    padding-left: 5px;
  }
  
  .exit-button {
    background-color: #c0c0c0;
    border: 1px solid #000;
    margin-left: 2px;
    cursor: pointer;
  }
  
  .tour-dates-list {
    margin-top: 20px;
    text-align: left;
  }

  .tour-dates-item {
    margin-bottom: 20px;
    text-align: center;
  }

  .tour-dates-city {
    font-size: 1.5em;
    font-weight: bold;
  }

  .tour-dates-date {
    font-size: 1.2em;
    margin-top: 5px;
  }

  .tour-dates-venue {
    font-size: 1em;
    margin-top: 5px;
  }

  .buy-tickets-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }

  .buy-tickets-button:hover {
    background-color: #0056b3;
  }
  @media (max-width: 768px) {
    .tour-dates-modal-content {
      width: 80vw;
    }
  }

.home-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow-x: hidden; /* Hides the horizontal scrollbar */
  }
  .music-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100px;
  }

  .package-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 80px;
    height: 80px;
  }
  
  .icon-container {
    display: flex;
    width: 80vw;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    position: static;
    height: fit-content;
    padding: calc(100vh / 100 * 4.5) calc(100vw / 100 * 2);
  }
  
  .spinning-icon {
    width: 21vw;
    height: auto;
  }
  @media (max-width: 600px) {
    .spinning-icon {
      width: 33vw;
    }
  }
  
  .image-wrapper {
    position: absolute;
    width: calc(100vw / 10);
    height: calc(100vw / 10);
    overflow: hidden;
  }

  .vayda-gif {
    image-rendering: 'pixelated';
    height:calc(100vw / 10);
    width:calc(100vw / 10);
  }

  
  .texture-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    object-fit: cover;
    z-index: 10000000;
  }

/* Add this CSS to style the scrollbar */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f0f0f0; /* Background of the scrollbar track */
  border-top-left-radius: 0px; /* Rounded top-left corner for the track */
  border-top-right-radius: 0px; /* Rounded top-right corner for the track */
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background: rgb(0, 255, 26); /* Color of the scrollbar thumb */
  border-radius: 0px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #ff0088; /* Color of the thumb on hover */
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 600px) {
  h1{
    position:absolute;
    max-width: 70vw;
    left: 0;
    height: 10vh;
    font-size: 4rem;
    top: 0;
  }
  .icon-container {
    position: absolute;
    top: 10vh;
    width: 100vw;
  }
  .audio-toggle {
    position: absolute;
    max-width: 25vw;
    right: 0;
  }
  .header {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }
}

.vintage-terminal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000000;
    overflow: hidden;
}

h1{
    color:black;
    text-align: center;
    background-color: black;
}

.vintage-terminal-content {
    display: flex;
    flex-direction: column; /* Ensure vertical stacking */
    width: 80%;
    height: 70%;
    background-color: #c0c0c0;
    border: 2px solid #000;
    box-shadow: 2px 2px 5px #000;
    border-radius: 7px; /* Round the corners by 5px */
    overflow: hidden;
}
.lower-container {
  background-color: #c0c0c0;
  border: 2px solid #000;
  box-shadow: 2px 2px 5px #000;
  height: auto; /* Allow the container to adjust its height */
  overflow: scroll; /* Ensure content is not clipped */
  padding: 20px; /* Optional: Add padding for better spacing */
  box-sizing: border-box; /* Include padding in the element's total width and height */
  overflow-x: hidden;
}


.left-pane, .right-pane {
    width: 50%;
    padding: 10px;
    overflow-y: hidden;
    border-right: 1px solid #000;
}

.left-pane {
  display: flex;
  flex-wrap: wrap;
  flex-direction: rows;
  justify-content: center;
}

.right-pane {
    border-right: none;
}

.modal-title-bar {
    display: flex;
    justify-content: space-between; /* Revert to space-between */
    align-items: center;
    background-color: #000080; /* Example background color */
    color: #fff; /* Text color */
    padding: 5px;
    z-index: 10000000;
}

.exit-button {
    background-color: #c0c0c0;
    border: 1px solid #000;
    margin-left: 2px;
    cursor: pointer;
}

.grid-item {
    cursor: pointer;
    margin-bottom: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    height: min-content;
}

.grid-item:hover {
    background-color: #e0e0e0;
}

.grid-image {
    width: 100%;
    height: auto;
}

.vintage-terminal-content form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vintage-terminal-content input[type="password"] {
    font-size: 1.5em; /* Increase the font size */
    padding: 10px; /* Add padding for a bigger input box */
    margin-bottom: 20px; /* Add some space below the input */
    width: 80%; /* Make the input wider */
    max-width: 400px; /* Set a maximum width */
}

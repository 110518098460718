.music-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000000;
    padding: 0px;
  }
  
  .music-modal-content {
    position: relative; /* Add this line */
    background-color: #c0c0c0;
    color: black;
    padding: 0px;
    border-radius: 5px;
    width: 50vw;
    height: 80vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align items at the top */
  }
  
  .close-button {
    margin-top: 10px;
    background-color: #f44336;
    color: black;
    border: none;
    padding: 0px;
    cursor: pointer;
  }

.modal-title-bar {
    display: flex;
    justify-content: space-between; /* Revert to space-between */
    align-items: center;
    background-color: #000080; /* Example background color */
    color: #fff; /* Text color */
    padding: 5px;
    width: 100%;
    z-index: 10000000;
}

.exit-button {
    background-color: #c0c0c0;
    border: 1px solid #000;
    margin-left: 2px;
    cursor: pointer;
    z-index: 99999999999999999999999;
}

.music-modal-icon {
  position: relative;
  width: 15vw; /* Adjust width to fit two icons per row */
  max-height: 15vh; /* Ensure each icon is only 25% of the modal height max */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.music-modal-icon img {
  max-width: 20vw;
  max-height: 20vh; /* Ensure the image doesn't exceed the icon's height */
}

.music-modal-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 50vw;
  height: 80vh;
  overflow-y: scroll; /* Remove scroll */
  margin-top: -2em; /* Move up by 1.5em */
}
  @media (max-width: 768px) { /* Adjust the max-width as needed for your mobile breakpoint */
    .music-modal-content {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      overflow-y: scroll;
      width: 80vw;
      max-width: 80vw;
    }
    .music-modal-icons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 80vw;
      height: auto;
      overflow-y: visible;
      margin-top: 0; /* Move up by 1.5em */

    }
    .music-modal-icon {
      width: 80vw;
      height: auto;
      padding: 10px;
      max-height: 80vh;
    }
    .music-modal-icon img {
      width: 70vw;
      height: 70vw; /* Lock the height to the width to maintain a 1:1 ratio */
      max-width: 70vw;
      max-height: 70vw;
    }
  }
  

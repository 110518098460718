.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    font-family: "Silkscreen", sans-serif;
  }
  input {
    font-family: "Silkscreen", sans-serif;
  }
  button {
    font-family: "Silkscreen", sans-serif;
    margin: 10px;
  }
  
  .modal-content {
    background-color: white;
    padding: 0px;
    padding-top: 0px;
    padding-left: 0px;
    border-radius: 5px;
    width: 30vw;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    z-index: 100001;
  }
  
  .modal-title-bar {
    display: flex;
    justify-content: space-between; /* Revert to space-between */
    align-items: center;
    background-color: #000080; /* Example background color */
    color: #fff; /* Text color */
    padding: 0px;
    border-radius: 5px 5px 0 0; /* Rounded corners at the top */
    z-index: 10000000;
  }
  
  .modal-title {
    font-size: 1.5em; /* Adjust the title size as needed */
  }
  
  .close-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.2em; /* Adjust size as needed */
  }
  @media (max-width: 768px) {
    .modal-content {
      width: 80vw;
    }
  }
